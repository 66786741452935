import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import * as Sentry from '@sentry/angular-ivy';
import { EnvVarsService } from './app/env-vars.service';
const environment = new EnvVarsService();
const SENTRY_ID_ = environment.getEnvKey('SENTRY_ID_');
const SENTRY_ENV = environment.getEnvKey('SENTRY_ENV');
const SENTRY_RELEASE = environment.getEnvKey('SENTRY_RELEASE');

if (SENTRY_ID_ && SENTRY_ENV) {
  Sentry.init({
    environment: SENTRY_ENV,
    release: SENTRY_RELEASE,
    dsn: SENTRY_ID_,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if (
  environment.getEnvKey('production') ||
  environment.getEnvKey('IS_COMMERCIAL')
) {
  enableProdMode();
  if (window) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    window.console.log = function () {};
  }
} else {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  window.console.warn = function () {};
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
