import { Injectable } from '@angular/core';
import { LayersBtn, OverlayLayer } from '@dashboard/dashboard-store';
import { CRS, Map, TileLayer, WMSOptions, tileLayer } from 'leaflet';
import lodash from 'lodash';
import { EnvVarsService } from 'src/app/env-vars.service';

type layers = { baseLayers: LayersBtn; overlay: LayersBtn };
@Injectable({
  providedIn: 'root',
})
export class MapLayersService {
  private MAP_URL = this.environment.getEnvKey('MAP_URL');
  private basemaps: Record<string, TileLayer.WMS> = {};
  private _map: Map;

  private previewsLayers: string[] = [];

  constructor(private environment: EnvVarsService) {}

  private WMS_PROPS: WMSOptions = {
    format: 'image/png', // Specify the image format
    version: '1.3.0', // Specify the WMTS version
    transparent: true, // Set transparency if your layer has transparency
    // tileSize: 256, // Tile size
    // crs: CRS.EPSG4326, // Use the appropriate CRS
    crs: CRS.EPSG3857, // Use the appropriate CRS
    attribution: 'Konnecta',
  };

  private _MapLayers!: layers;

  public set setMapLayers(layers: layers) {
    console.log(layers);

    this._MapLayers = layers;
  }

  public set Map(map: Map) {
    this._map = map;
  }

  private RemoveLayers(keys: string[]): void {
    console.log('RemoveLayers', keys);

    if (
      keys.length === 0 ||
      !this.basemaps ||
      Object.keys(this.basemaps).length === 0
    )
      return;
    keys.map((key) => {
      console.log('Removing layer', key);
      this._map.removeLayer(this.basemaps[key]);
      delete this.basemaps[key];
    });
  }

  public selectLayer(id: string): void {
    console.log(this.basemaps[id]);

    this._map.addLayer(this.basemaps[id]);
  }
  public ReInstantiateNaviqoreLayers(layers: {
    baseLayer: string;
    overlayLayers: string[];
  }) {
    const baseMapLayer: OverlayLayer =
      this._MapLayers.baseLayers.layers[layers.baseLayer];

    const overlayMapLayers: OverlayLayer[] =
      this._MapLayers.overlay.layers.filter((_, index) =>
        layers.overlayLayers.map((d) => Number(d)).includes(index)
      );
    const zoomLvl = Math.round(this._map.getZoom() * 10) / 10;

    const activeLayers = [baseMapLayer, ...overlayMapLayers];

    const layersPerZoom = activeLayers.reduce((acc, layer) => {
      layer.layerGroup.forEach((d) => {
        if (d.startZoom <= zoomLvl && d.endZoom > zoomLvl) {
          acc.push(d.layer);
        }
      });
      return acc;
    }, [] as string[]);

    // console.log('Layers to be active', zoomLvl, layersPerZoom);
    // find what layers to remove and what layers to add

    const missingLayers = this.FindMissingValues(
      layersPerZoom,
      this.previewsLayers
    );

    this.RemoveLayers(missingLayers);

    this.previewsLayers = layersPerZoom;

    this.basemaps = {
      ...this.SetActiveLayers(layersPerZoom),
      ...this.basemaps,
    };

    Object.keys(this.basemaps).map((layer) => {
      //if already enabled stop
      if (this._map.hasLayer(this.basemaps[layer])) {
        console.log(`Layer - ${layer} is laready active`);
        return;
      }
      // console.log(`Enabling - ${layer}`);
      this.selectLayer(layer);
    });
    //We bring first always the base layer. It will be the first in the array because it will always one of them selected
    this.basemaps[layersPerZoom[0]].bringToBack();
  }

  public ReInstantiateLayers(layers: {
    baseLayer: string;
    overlayLayers: string[];
  }) {
    const isCustomMap =
      this._MapLayers.baseLayers.layers[
        Number(layers.baseLayer)
      ].layerGroup[0].layer.includes('http');

    layers.overlayLayers = isCustomMap ? [] : layers.overlayLayers;

    this.ReInstantiateNaviqoreLayers(layers);
  }

  private FindMissingValues(curr: string[], prev: string[]) {
    return lodash.difference(prev, curr);
  }

  private SetActiveLayers(layers: string[]) {
    return layers.reduce((acc, layer) => {
      const isCustomMap = layer.includes('http');

      acc[layer] = isCustomMap
        ? this.GenerateLeafletTileLayer(layer)
        : this.GenerateWMSTileLayer(layer);
      return acc;
    }, {});
  }

  private GenerateLeafletTileLayer(layer: string) {
    return tileLayer(layer);
  }

  private GenerateWMSTileLayer(layer: string) {
    return tileLayer.wms(this.MAP_URL, {
      ...this.WMS_PROPS,
      layers: layer,
    });
  }
  public Initialize() {
    return;
  }
}
